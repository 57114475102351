import React from 'react'

const Proposals = () => {
  return (
    <div>
      Proposals
    </div>
  )
}

export default Proposals
