import React from 'react'

const Tasks = () => {
  return (
    <div>
      Tasks
    </div>
  )
}

export default Tasks
